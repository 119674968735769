import classNames from 'classnames';
import styles from './../BetaFamilyNode.module.css';
import Moment from 'moment';

export default function BetaFamilyNode({
  node,
  isRoot,
  onSubClick,
  style,
}) {
  return (
    <div className={styles.root} style={style}>
      <div className={classNames(
          styles[node.DeathType],
          styles.inner,
          styles[node.gender],
          isRoot && styles.isRoot,
        )}>
        <div className={styles.content}>
            <div className='font-bold my-2 leading-5'>{node.name}</div>
            <div className='flex justify-center items-center my-4 '>
              <img
                className="object-cover h-36 w-36"
                src={
                  node.avatar
                  ? `${process.env.REACT_APP_PERSONS_STATIC}${node.avatar}`
                  : `${process.env.REACT_APP_COMMON_STATIC}/placeholder.jpg`
                }
                alt={node.name}
              />
            </div>
          <div >
          {Moment(node.birthDate).format('DD.MM.Y')} {node.deathDate ? `- ${Moment(node.deathDate).format('DD.MM.Y')}` : ''}
          </div>
          {node.birthPlace}
        </div>
        {node.hasSubTree && node.id && (
          <div 
            className={classNames(styles.sub, styles[node.gender])}
            onClick={e => {
              e.stopPropagation();
              onSubClick(node.id);
            }}
          />
        )}
      </div>
    </div>
  );
}

