import { Link } from 'react-router-dom';
import ApproximateDate from '../../../common/ApproximateDate';

export default function PersonSmall({ person, sibsCountShow }: any) {
  return (
    <div className=" flex items-center justify-end relative person-small">
      <div>
        <Link
          key={person.id}
          to={`/tree/${person.id}/`}
          className="hover:scale-125 hover:transition-all block z-10 relative"
        >
          <img
            className="w-14 h-14 object-cover border-2 border-white rounded-full   mx-auto"
            src={
              person.avatar
                ? `${process.env.REACT_APP_PERSONS_SMALL_STATIC}${person.avatar}`
                : `${process.env.REACT_APP_COMMON_STATIC}/placeholder.jpg`
            }
            alt={`${person.surname} ${person.givenNames}`}
            title={`${person.surname} ${person.givenNames}`}
          />
        </Link>
        <div
          className={`w-20 mt-1 leading-5 block bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded truncate ${
            person.choosen ? 'bg-orange-300' : ''
          }`}
        >
          {`${person.givenNames} ${person.surname}   `}
        </div>
        <div
          className={`w-20 leading-5 block  text-blue-800 text-xs font-medium text-center  px-2.5 py-0.5 rounded  truncate`}
        >
          <ApproximateDate
            date={person.birthAt}
            isApproximate={person.isApproximateBirth}
          />
        </div>
      </div>
      {person.sibsCount && sibsCountShow ? (
        <div
          className={`w-14 h-14 rounded-full text-1xl font-bold text-center  bg-gray-100 absolute top-0 -right-6 flex items-center justify-center tracking-tight `}
        >
          +{person.sibsCount}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
