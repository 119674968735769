import { FC, useEffect, useState } from 'react';
import PersonPhotoService from '../../api/PersonPhotoService';
import { useParams, Link } from 'react-router-dom';
import LightBox from '../../modules/profile/components/LightBox';

const Photos: FC = () => {
  const { id } = useParams();

  const [personPhotos, setPersonPhotos] = useState([] as any);

  useEffect(() => {
    loadPhotos();
  }, [id]);

  const loadPhotos = () => {
    PersonPhotoService.getGroupByYear(Number(id)).then(({ data }) => {
      setPersonPhotos(data);
    });
  };

  if (!personPhotos.data) return <></>;

  return (
    <div className="container mx-auto px-2 pb-10">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <div className="overflow-x-auto relative mt-4 m-auto p-6 w-full sm:w-2/4">
          <Link to={`/profile/${id}/`} className="underline">
            Назад
          </Link>

          {
            Object.keys(personPhotos.data).map((year: any) => {
              return (
                <div
                key={year}
                className="mt-4 mb-4 flex gap-4"
              >
                <h3 className="text-right font-bold whitespace-nowrap">{year} г.</h3>
                <div>
                    <LightBox
                      key={year.toString()}
                      images={personPhotos.data[year]}
                      imgClasses="w-40"
                      wrapperClasses="flex"
                    />
                </div>
              </div>

              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default Photos;
