import PersonService from '../api/PersonService';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; 
import ReactFamilyTree from "react-family-tree";
import BetaFamilyNode from '../modules/tree/components/BetaFamilyNode';
import { IPerson } from '../models/IPerson';
import { PinchZoomPan } from '../modules/pinch-zoom/PinchZoomPan';
import { useNavigate } from 'react-router-dom';


const WIDTH_CARD = 260;
const HEIGHT_CARD = 280;

interface IRelativePerson  {
  id: string | undefined;
  type: string;
}

const BetaTree: FC = (props: any) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [personNodes, setPersonNodes] = useState([] as any);
  const [person, setPerson] = useState({} as any);
  const [rootId, setRootId] = useState(id?.toString());
  const [lastRootId, setLastRootId] = useState('' as any);


  useEffect( () => {
    const personId = Number(id);

    if (!personId) {
      console.log('redirect');
    }

    getPerson(personId).then(() =>{
      getAllPerson().then(({data}) => {
        getAllRelative(data.data)
      });
    });

  }, [id]);
  
  const setAnotherRootId = (id: string) => {
    setLastRootId(rootId);
    setRootId(id);
    navigate(`/beta-tree/${id}/`);
  };;

  const getPerson = async (personId: number) => {
    const response: any = await PersonService.getPerson(Number(personId));
    setPerson(response.data);
  }

  const getAllPerson = async () => {
    return await PersonService.getSlimList();
  }

  const getAllRelative = (data: IPerson[]) => {
    const treeStructureData = [];

    for (let i = 0; i < data.length; i++) {
      // @doc подготовка массива родителей
      const parents: IRelativePerson[] = [];
      if (data[i].parentMaleId) {
        parents.push({
          id: data[i].parentMaleId?.toString(),
          type: 'blood',
        });
      }

      if (data[i].parentFemaleId) {
        parents.push({
          id: data[i].parentFemaleId?.toString(),
          type: 'blood',
        });
      }

      const currentPerson: IPerson = data[i];
      treeStructureData[i] = {
        id: currentPerson.id.toString(),
        gender: currentPerson.gender,
        avatar: currentPerson.avatar,
        name: `${currentPerson.surname} ${currentPerson.givenNames}`,
        birthDate: currentPerson.birthAt,
        deathDate: currentPerson.deathAt,
        birthPlace: currentPerson.birthPlace,
        parents: parents,
        children: getChildrens(data, currentPerson),
        siblings: getSibs(data, parents),
        spouses:[],
        placeholder: false,
      };
    }
    
    setPersonNodes(treeStructureData);
  }

  const getChildrens = (persons: IPerson[], person: IPerson): IRelativePerson[] => {
    const childrens: IRelativePerson[] = [];
    for (let i = 0; i < persons.length; i++) {
      if (persons[i].parentMaleId === person.id || persons[i].parentFemaleId === person.id) {
        childrens.push({
          id: persons[i].id.toString(),
          type: 'blood',
        });
      }
    }

    return childrens;
  }

  const getSibs =  (persons: IPerson[], parents: IRelativePerson[]): IRelativePerson[] => {
    const sibs: IRelativePerson[] = [];
    for (let i = 0; i < persons.length; i++) {
      for (let j = 0; j < parents.length; j++) {
        if (persons[i].parentMaleId === parents[j].id) {
          sibs.push({
            id: persons[i].id.toString(),
            type: 'blood',
          });
        }
      }
    }
    
    return sibs;
  }

  if (!person || !rootId || personNodes.length === 0) {
    return <div>404</div>;
  }

  return (
    <div>
      <section className="px-2 py-32 bg-gray-200 md:px-0">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-between items-center mx-auto flex-col sm:flex-row">
            <div className='mb-3'>
              <h1 className=" text-3xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-3xl">
                Просмотр дерева
              </h1>
              <a
                href="/cabinet/person"
                className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  underline text-md"
              >
                Вернуться к списку профилей
              </a>
            </div>
            <div className='gap-4 flex items-center '>
              <a
                type="button"
                className="py-2.5 px-5  text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 "
                href={`/tree/${id}/`}
              >
                Перейти к старому дереву
              </a>
              <a
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  md:mr-0 w-full sm:w-auto"
                href="/cabinet/person/add"
              >
                Добавить профиль
              </a>
            </div>
          </div>

          <div className='flex bg-gray-100 height-full relative'>
            {
              lastRootId && (
                <button
                  type="button"
                  className="z-50 absolute left-5 top-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0  w-full sm:w-auto"
                  onClick={() => setAnotherRootId(lastRootId)}
                >
                  Вернуться назад
                </button>
              )
            }

            <PinchZoomPan
              captureWheel
              min={0.2}
              max={2.5}
              key={rootId}
              className="flex-1 tree-height"
            >
              <ReactFamilyTree  
                nodes={personNodes}
                rootId={rootId}
                width={WIDTH_CARD}
                height={HEIGHT_CARD}
                className="bg-white border-2 border-gray-200 rounded-xl"
                renderNode={(node: any) => (
                  <BetaFamilyNode
                    key={node.id}
                    node={node}
                    isRoot={node.id === rootId}
                    onSubClick={setAnotherRootId}
                    style={{
                      width: WIDTH_CARD,
                      height: HEIGHT_CARD,
                      transform: `translate(${node.left *
                        (WIDTH_CARD / 2)}px, ${node.top * (HEIGHT_CARD / 2)}px)`
                    }}
                  />
                )}
              />
            </PinchZoomPan>
          </div>

          <div className=' bg-slate-50 rounded-xl w-full text-sm p-4 mt-5'>
            <h1 className='text-gray-600 text-lg font-bold'>Как ориентироваться в дереве</h1>
            <ul className='list-disc pl-5'>
              <li>Визуально сверху вниз идет расположение от родителей к детям;</li>
            </ul>
          </div>
        </div>

        
      </section>
    </div>
  );
};

export default BetaTree;
