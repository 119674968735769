import { FC, useEffect, useState } from 'react';
import PersonDetail from '../../modules/profile/components/PersonDetail';
import PreviewHistory from '../../modules/profile/components/PreviewHistory';
import PreviewPhotos from '../../modules/profile/components/PreviewPhotos';
import { useParams, Link } from 'react-router-dom';
import PersonService from '../../api/PersonService';
import PersonPhotoService from '../../api/PersonPhotoService';
import PersonHistoryService from '../../api/PersonHistoryService';
import { IPerson } from '../../models/IPerson';
import { IPagination } from '../../modules/profile/types';
import { IPersonPhoto } from '../../models/IPersonPhoto';
import { IPersonHistory } from '../../models/IPersonHistory';

const Profile: FC = () => {
  const { id } = useParams();

  const [person, setPerson] = useState({} as IPerson);
  const [personPhotos, setPersonPhotos] = useState(
    {} as IPagination<IPersonPhoto>,
  );
  const [personHistory, setPersonHistory] = useState(
    {} as IPagination<IPersonHistory>,
  );

  useEffect(() => {
    PersonService.getPublicPerson(Number(id)).then(({ data }) => {
      setPerson(data);
    });

    PersonPhotoService.getList(1, {
      personId: Number(id),
    }).then(({ data }) => {
      setPersonPhotos(data);
    });

    PersonHistoryService.getList(1, { personId: id }).then(({ data }) => {
      setPersonHistory(data);
    });
  }, [id]);

  return (
    <div className="container mx-auto px-2 pb-10">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <div className="overflow-x-auto relative mt-4 m-auto p-6 w-full sm:w-2/4">
          <PersonDetail person={person} />

          {personPhotos?.data?.length ? (
            <div className="my-5">
              <PreviewPhotos personPhotos={personPhotos} />
              <div className="text-center mb-10 mt-5">
                <Link
                  to={`/profile/${id}/photos`}
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0  w-full sm:w-auto"
                >
                  Все фото
                </Link>
              </div>
            </div>
          ) : (
            <div className="text-center">Нет добавленных фото</div>
          )}

          {personHistory?.data?.length ? (
            <div className="my-5">
              <PreviewHistory
                personHistory={personHistory}
                personId={id}
                person={person}
              />
              <div className="text-center mb-10 mt-5">
                <Link
                  to={`/profile/${id}/histories`}
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0  w-full sm:w-auto"
                >
                  Все истории
                </Link>
              </div>
            </div>
          ) : (
            <div className="text-center">Нет добавленных историй</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
