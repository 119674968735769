import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import PersonHistoryService from '../../api/PersonHistoryService';
import PersonAudioRecordService from '../../api/PersonAudioRecordService';
import LightBox from '../../modules/profile/components/LightBox';
import ApproximateDate from '../../modules/common/ApproximateDate';
import moment from 'moment';
import HistoryPhotoService from '../../api/HistoryPhotoService';

export default function HistoriesDetail() {
  const [history, setHistory] = useState({} as any);
  const [photos, setPhotos] = useState([] as any);
  const [audioRecords, setAudioRecords] = useState([] as any);

  const { historyId, id } = useParams();

  const replaceWithBaseMarkdown = () => {
    const regExpImgMask: any = [];
    const imagesKey: any = [];

    /* eslint-disable no-useless-escape */
    let prepareDetailText = history
      .detailText
      .replace(/[\*\_]{2}([^\*\_]+)[\*\_]{2}/g, '<b>$1</b>')
      .replace(/[\*\_]{1}([^\*\_]+)[\*\_]{1}/g, '<i>$1</i>')
      .replace(/\n/g, '<br />');

    /* eslint-disable no-useless-escape */
    photos.forEach((photo: string, index: number) => {
      regExpImgMask[`\\[\\[${index + 1}\\]\\]`] = photo;
      imagesKey[`\[\[${index + 1}\]\]`] = photo;
    });
    
    if (photos.length) {
      prepareDetailText = prepareDetailText.replace(new RegExp(Object.keys(regExpImgMask).join('|'), 'gi'), (matched: any) => {
        return `
        <div>
          <img 
            class="img-fluid block my-3 object-contain" 
            src="${process.env.REACT_APP_PERSONS_PHOTO_STATIC}${imagesKey[matched].photo}" 
          />
          <div class="text-gray-400 text-center">
          ${imagesKey[matched].description} ${moment(imagesKey[matched].year).format('Y')} г.
          </div>
        </div>
        `;
      });
    }
    
    return prepareDetailText;
  };

  useEffect(() => {
    PersonHistoryService.detail(Number(historyId)).then(({ data }) => {
      setHistory(data.history);
    });

    HistoryPhotoService.getList(Number(historyId)).then(({data}) => {
      setPhotos(data.data.map((el:any) => {
        return {
          photo: el.personPhoto.photo,
          description: el.personPhoto.description,
          year: el.personPhoto.year
        }
      }));
    });

    PersonAudioRecordService.getList(1, {
      personHistoryId: historyId,
      personId: id,
    }).then(({ data }) => {
      setAudioRecords(data);
    });
  }, []);

  if (!history.id) return <></>;

  
  const audioReList = audioRecords.data && audioRecords.data.map((el: any) => {
      return (
        <div
          key={el.id}
          className="flex justify-between border-b p-4"
        >
          <div>
            <audio className='block' controls src={`${process.env.REACT_APP_PERSONS_AUDIO_RECORD_STATIC}${el.audio}`} />
            <p className='mt-4'>{el.description}</p>
          </div>
        </div>
      );
  });

  return (
    <div className="container mx-auto px-2 pb-10">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <div className="overflow-x-auto  mt-4 m-auto p-6 w-full sm:w-2/4">
          <Link onClick={() => window.history.back()} to={''} className="underline">
            Назад
          </Link>
          <h1 className="mb-1 mt-6 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-3xl">
            {history.title || 'Без заголовка'}
          </h1>
          <div className='text-gray-500 mb-4'>
            <ApproximateDate
              date={history.year}
              isApproximate={!!history.toYear}
            />
            {history.toYear && ' - '}
            {history.toYear && (
              <ApproximateDate
                date={history.toYear}
                isApproximate={!!history.toYear}
              />
            )}
          </div>
          {photos && (
            <div className="mb-3 flex gap-4">
              <LightBox images={photos} imgClasses="h-24 w-24" wrapperClasses="flex flex-wrap gap-2" />
            </div>
          )}
          { audioReList }
          <div
            dangerouslySetInnerHTML={{ __html: replaceWithBaseMarkdown() }}
          />
        </div>
      </div>
    </div>
  );
}
