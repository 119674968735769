import { FC, useEffect, useState } from 'react';
import AuthService from '../api/AuthService';
import { useNavigate, useParams } from 'react-router-dom';
import { useActions } from '../hooks/useActions';
import PersonHistoryService from '../api/PersonHistoryService';
import Pagination from '../modules/common/Pagination';
import { IPersonHistory } from '../models/IPersonHistory';
import HistoryItem from '../modules/profile/components/HistoryItem';

const Guest: FC = () => {
  const [histories, setHistories] = useState([] as any);
  const { hash } = useParams();
  const { loginGuest } = useActions();
  let navigate = useNavigate();

  useEffect(() => {
    if (!hash) {
      navigate('/');
      return;
    }

    AuthService.guestAuth(hash ?? '').then(({ data }: any) => {
      const { token, userId, username, group } = data;
      loginGuest(token, userId, username, group);
      loadHistory()
    });
  }, []);

  const handleSetPage = (page: number) => {
    loadHistory(page);
  };

  const loadHistory = (page = 1) => {
    PersonHistoryService.getList(page, {ownerId: true}).then(({ data }) => {
      setHistories(data.data);
    });
  };
  
  if (histories.length === 0) return <></>;

  const historyItems = histories.map((el: IPersonHistory) => {
    return (
      <HistoryItem
        history={el}
        accessEdit={false}
        personId={el.id}
        key={el.id}
      />
    );
  });

  return (
    <div className="container mx-auto px-2 pb-10">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <div className="overflow-x-auto relative mt-4 m-auto p-6 w-full sm:w-2/4">
          <h1 className="mb-4 mt-6 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-3xl ">
            Истории
          </h1>
          <div className="grid gap-4"> {historyItems}</div>
          <Pagination {...histories} setPage={handleSetPage} />
        </div>
      </div>
    </div>
  );


};

export default Guest;
