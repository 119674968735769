import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { Link } from 'react-router-dom';
import ApproximateDate from '../../../common/ApproximateDate';
import { UserGroups } from '../../../../types';

export default function PersonSelf(props: any) {
  const { isAuth } = useTypedSelector((state) => state.auth);  
  const { user } = useTypedSelector((state) => state.auth);

  const isGuest = UserGroups.guest === user.group;

  return (
    <div className="active-person relative flex items-center flex-row bg-white border border-gray-200 rounded-lg shadow w-1/3 hover:bg-gray-100  h-36 ">
      <img
        className="object-cover h-36 w-36"
        src={
          props.person.avatar
            ? `${process.env.REACT_APP_PERSONS_STATIC}${props.person.avatar}`
            : `${process.env.REACT_APP_COMMON_STATIC}/placeholder.jpg`
        }
        alt={props.person.surname}
      />
      <div className="flex flex-col justify-between p-4 leading-normal">
        <h5 className="mb-2 text-lg leading-5 font-bold tracking-tight text-gray-900  overflow-hidden ">
          {props.person.surname} {props.person.givenNames}
        </h5>
        <p className="font-normal text-sm  text-gray-700">
          <ApproximateDate
            date={props.person.birthAt}
            isApproximate={props.person.isApproximateBirth}
          />
          {props.person.deathAt && ' - '}
          {props.person.deathAt && (
            <ApproximateDate
              date={props.person.deathAt}
              isApproximate={props.person.isApproximateDeath}
            />
          )}
        </p>
        {props.person.birthPlace && (
          <p className="font-normal text-sm from-zinc-200 text-gray-700">
            {props.person.birthPlace}
          </p>
        )}
        { isGuest && (
          <div className="text-right gap-3 flex mt-3">
            <Link
                className="underline block"
                to={`/profile/${props.person.id}`}
              >
                профиль
            </Link>
            <Link
                className="underline block"
                to={`/profile/${props.person.id}/histories/`}
              >
                истории
            </Link>
            <Link
                className="underline block"
                to={`/profile/${props.person.id}/photos/`}
              >
                фото
            </Link>
          </div>
          )}
        {isAuth && !isGuest && (
          <div className="text-right gap-3 flex mt-3">
            <Link
              className="underline block"
              to={`/profile/${props.person.id}`}
            >
              профиль
            </Link>
            <Link
              className="underline block"
              to={`/cabinet/person-history/${props.person.id}`}
            >
              истории
            </Link>
            <Link
              className="underline block"
              to={`/cabinet/person-photo/${props.person.id}`}
            >
              фото
            </Link>
            {Number(user.userId) === Number(props.person.userId) ? (
              <Link
                className="underline block"
                to={`/cabinet/person/edit/${props.person.id}`}
              >
                ред.
              </Link>
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    </div>
  );
}
